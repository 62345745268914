.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
	color: #de2828 !important;
}
.client-form-wrapper label,
.form-wrapper label {
	display: block;
}

.from-form--1 .form-check {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	height: 5vh;
}
.from-form--1 .form-check label {
	display: inline;
	padding: 0 1vh;
	font-weight: bold;
}
.from-form--1 .form-check-input {
	margin-top: 0;
}
.from-form--1 .form-check input[type='checkbox'] {
	width: 4%;
}
.from-from-appliance .form-check input[type='checkbox'] {
	width: 9%;
}

@media only screen and (max-width:768px){

	.packingitemsTable{
		min-width:80vh;

	}
	.cosnt-form-wrapper .container{
		padding: 0 !important
	}
	.rcolp > [class*="col"], .rcolp > [class*="col-"]{
		padding: 0 !important
	}
	.table thead th, .MuiStepLabel-label.MuiStepLabel-alternativeLabel{
		font-size:12px
	}
	.MuiStep-horizontal{
		padding : 0 !important
	}
	.MuiStepper-root {
		padding: 24px 1vh !important;
	}
	.from-from-appliance .form-check input[type='checkbox']{
		width:5%
	}
}
@media only screen and (max-width:580px){
	.packing-date-box{
		padding:0 30px
	}
}