.rn-header {
	height: 17vh;
	background: #055f9e;
	box-shadow: 0 0 10px #231e1e;
	& .rn-header-inner {
		height: 17vh;
		justify-content: space-between;
		align-items: center;
		display: flex;
	}
	& .rn-navbar-brand {
		img {
			width: 50vh;
		}
	}
	h1 {
		color: #fff;
		font-size: 44px;
		margin: 0;
		text-align: center;
	}
}

@media only screen and (max-width: 768px) {
	.rn-header h1 {
		font-size: 28px;
	}
}

@media only screen and (max-width: 580px) {
	.rn-header h1 {
		font-size: 18px;
	}
	.packing-form-wrapper .container {
		padding: 0 !important;
	}
}
